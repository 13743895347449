import React from "react";
import { Button } from "flowbite-react";
import { HiChevronLeft } from "react-icons/hi";

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
        console.log(error, errorInfo)
      // You can also log the error to an error reporting service
    //   logErrorToMyService(error, errorInfo);
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return <div className="flex min-h-screen flex-col items-center justify-center py-16">
        <img alt="" src="/images/illustrations/500.svg" className="lg:max-w-md" />
        <h1 className="mb-3 w-4/5 text-center text-2xl font-bold dark:text-white md:text-5xl">
          Something has gone seriously wrong
        </h1>
        <p className="mb-6 w-4/5 text-center text-lg text-gray-500 dark:text-gray-300">
          It's always time for a coffee break. We should be back by the time you
          finish your coffee.
        </p>
        <Button href="/">
          <div className="mr-1 flex items-center gap-x-2">
            <HiChevronLeft className="text-xl" /> Go back home
          </div>
        </Button>
      </div>;
      }
  
      return this.props.children; 
    }
  }

  export default ErrorBoundary;