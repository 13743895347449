import { useEffect, useState } from "react"
import { Navigate } from "react-router-dom";
export default function ProtectedRoutes({ children}) {
    const [isLogin, setIsLogin] = useState(true);
    useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) {
            setIsLogin(false);
        }
    }, []);
    return isLogin ? children : <Navigate to="/login" />;
}