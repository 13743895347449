import { BrowserRouter, Routes, Route  } from 'react-router-dom'
import React, { lazy, Suspense } from 'react'
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import history from './history'
import store from './redux/store';
import ErrorBoundry from './errorBoundry';
import ProtectedRoutes from './layouts/protectedRouter';

const AuthLayout = lazy(() => import('./layouts/authLayout'));
const AdminLayout = lazy(() => import('./layouts/adminLayout'));
const NotFound = lazy(() => import('./notFound'));
const Login = lazy(() => import('./views/auth/login'));
const Forgot = lazy(() => import('./views/auth/forgot'));
const Signup = lazy(() => import('./views/auth/signup'));
const Home = lazy(() => import('./views/index'));
const Plan = lazy(() => import('./views/admin/plan'));
const Search = lazy(() => import('./views/admin/search'));
const SearchResult = lazy(() => import('./views/admin/searchresult'));

function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ErrorBoundry>
        <ToastContainer />
        <HelmetProvider>
          <Provider store={store}>
            <BrowserRouter history={history}>
              <Routes>
                  <Route element={<AuthLayout />}>
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/forgot" element={<Forgot />} />
                  </Route>
                  <Route element={<ProtectedRoutes><AdminLayout /></ProtectedRoutes>}>
                    <Route path="/" element={<Home />} />
                    <Route path="/search" element={<Search />} />
                    <Route path="/search-result" element={<SearchResult />} />
                    <Route path="/plan" element={<Plan />} />
                  </Route>
                <Route path="*" element={<NotFound />} />
              </Routes>
            </BrowserRouter>
          </Provider>
        </HelmetProvider>
      </ErrorBoundry>
    </Suspense>
  );
}

export default App;
