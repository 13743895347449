import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    categoryList: [],
    error: []
};
const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        setLoading: (state) => {
            state.loading = true;
            state.categoryList = {};
            state.error = {};
        },
        categoryList: (state, action) => {
            state.loading = false;
            state.categoryList = action.payload.list;
            state.error = {}
        },
        handleError: (state) => {
            state.loading = true;
            state.categoryList = {};
            state.error = {code: 500, message: "Something went wrong!"};
        }
    }
})

export const { setLoading, categoryList, handleError } = categorySlice.actions;
export default categorySlice.reducer;